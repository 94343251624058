import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				GearGurus
			</title>
			<meta name={"description"} content={"Експертний догляд за вашою їздою"} />
			<meta property={"og:title"} content={"GearGurus"} />
			<meta property={"og:description"} content={"Експертний догляд за вашою їздою"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65f2c939fdcae00021e01521/images/3-4.jpg?v=2024-03-14T13:00:58.961Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65f2c939fdcae00021e01521/images/3-4.jpg?v=2024-03-14T13:00:58.961Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65f2c939fdcae00021e01521/images/3-4.jpg?v=2024-03-14T13:00:58.961Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65f2c939fdcae00021e01521/images/3-4.jpg?v=2024-03-14T13:00:58.961Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65f2c939fdcae00021e01521/images/3-4.jpg?v=2024-03-14T13:00:58.961Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65f2c939fdcae00021e01521/images/3-4.jpg?v=2024-03-14T13:00:58.961Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65f2c939fdcae00021e01521/images/3-4.jpg?v=2024-03-14T13:00:58.961Z"} />
		</Helmet>
		<Components.Header2>
			<Override slot="quarklycommunityKitMobileSidePanel" />
			<Override slot="box2" />
			<Override slot="socialMedia" />
			<Override slot="text" />
			<Override slot="image" />
		</Components.Header2>
		<Section background="--color-primary" padding="80px 0 80px 0">
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				padding="0px 200px 0px 200px"
				lg-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 30px 0px"
					font="--headline1"
					color="--light"
					text-align="center"
					sm-font="normal 700 62px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Зв'яжіться з нами
				</Text>
				<Text margin="0px 0px 48px 0px" font="--lead" color="--light" text-align="center">
					Щоб отримати симфонію механічних знань і неперевершеної відданості автомобільній досконалості, виберіть GearGurus. Зв’яжіться з нами, щоб дізнатися більше, як ми можемо збільшити продуктивність і термін служби вашого автомобіля. Незважаючи на велику кількість деталей, суть нашої послуги проста: GearGurus — це найкраще задоволення від водіння.
				</Text>
				<Text margin="0px 0px 48px 0px" font="--headline2" color="--light" text-align="center">
					0975627271
					<br />
					<br />
					Mykoly Rudenka St, 29А, Dnipro,{" "}
					<br />
					Dnipropetrovsk Oblast, 49000
					<br />
				</Text>
			</Box>
		</Section>
		<Components.Footer12>
			<Override slot="link4">
				0975627271
				<br />
				<br />
				Mykoly Rudenka St, 29А, Dnipro,{" "}
				<br />
				Dnipropetrovsk Oblast, 49000
				<br />
				<br />
				Copyright @2024. GearGurus
			</Override>
		</Components.Footer12>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65d5d38ffdcae00021def28a"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});